:root {
  --box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.4);
  --blue-color: #2d75da;
  --title-color: #3c4048;
  --text-color: #383838;
  --red-color: #cf1a1a;
  --white-color: #ffffff;
  --purple-color: #a019ce;
  --green-color: #15d2b0;
}

body {
  background-color: #f0f7ff !important;
  color: var(--text-color);
}

body .css-1h15ts-MuiPaper-root-MuiCard-root,
body .css-aim41u {
  color: var(--text-color);
}

.css-3agax9-MuiDrawer-docked {
  position: fixed;
  margin-right: 8px;
  z-index: 2;
}

.css-aim41u {
  position: relative;
}

header,
body .css-1o6vssb-MuiPaper-root-MuiAppBar-root {
  color: var(--text-color);
}

.blue-color,
button.blue-color {
  color: var(--blue-color);
}

.popupCloseButton {
  box-shadow: none;
  margin-left: 10px;
  text-transform: uppercase;
  color: var(--text-color);
  box-shadow: none;
  border: solid 1px var(--text-color);
  transition: 0.5s;
  padding: 0 15px;
  border-radius: 4px;
}
.popupCloseButton:hover {
  background-color: var(--text-color);
  color: #fff;
}

.popupLabel {
  font-size: 16px;
  text-align: left;
  display: flex;
  margin-bottom: 30px;
}
.popupLabel span {
  margin-right: 10px;
}

.popupLabel > svg,
.popupLabel > span.material-icons-round,
.popupLabel > div > svg,
.popupLabel > button > svg,
.popupLabel > button > span.material-icons-round {
  color: var(--blue-color);
}

.popupInfoContainer h2 {
  font-size: 28px !important;
}

.popupButtonEdit {
  position: absolute;
  right: 0;
  top: 0;
}

.popupInfoTitleContainer {
  text-align: left;
  display: flex;
  line-height: 26px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: solid 1px #dedede;
}
.popupInfoTitleContainer span {
  margin-right: 10px;
}

.popupExtraInfo {
  margin-top: 15px;
  padding-top: 15px;
  border-top: solid 1px #dedede;
}

.editExpirationPopup .sweet-alert .rdt.rdtOpen .rdtPicker {
  position: relative;
  margin-top: -35px;
}

.page-title {
  font-size: 22px;
  font-weight: 700;
  color: var(--title-color);
}

.statistics-counter-card {
  padding: 15px 30px;
}

.statistics-counter-container {
  display: flex;
  align-items: center;
}

.statistics-counter-image-container {
  border-radius: 10px;
  /* padding: 10px; */
  margin-right: 20px;
  display: flex;
}

.statistics-counter-image {
  /* padding: 10px; */
  display: flex;
  /* border-radius: 10px; */
}

.statistics-counter-image > span {
  color: #fff;
  font-size: 80px;
}
.statistics-counter-image > img {
  width: 60px;
  height: auto;
}

.statistics-counter-text-container {
  font-size: 14px;
  line-height: 22px;
}

.statistics-counter {
  font-size: 30px;
  font-weight: bold;
  line-height: 35px;
  color: var(--title-color);
}

select.selectDefault {
  background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
    no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
  cursor: pointer;
}

.headerCustomersDropdown {
  background-color: transparent;
  font-size: 18px;
  color: var(--text-color);
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  padding-bottom: 2px;
  outline: none;
}

header .css-83yo6b-MuiIcon-root {
  color: var(--text-color);
}

.clientsViewArrayContainer {
  display: flex;
  margin-top: 10px;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.clientsViewArrayElement {
  margin-right: 10px;
  padding: 0px 5px 0px 15px;
  font-size: 15px;
  border: solid 1px #dedede;
  border-radius: 20px;
  margin-bottom: 5px;
}

.clientInputArrayButton {
  position: absolute;
  right: 5px;
  top: 5px;
}

.customDropdownContainer {
  position: absolute;
  left: 0;
  right: 0;
  top: 44px;
  z-index: 1;
  background-color: #fff;
  border: solid 1px #dedede;
  border-radius: 0;
}

.customDropdownClient {
  color: #000;
  background-color: #fff;
  transition: 0.3s;
  font-size: 15px;
  padding: 10px;
  border-bottom: solid 1px #dedede;
}
.customDropdownClient:hover,
.customDropdownClient.selected {
  background-color: #dedede;
}

.pagination-container {
  display: flex;
  /* justify-content: center; */
  justify-content: initial;
  margin-top: 50px;
}

.pagination-inner {
  display: flex;
}

.pagination {
  padding: 5px 15px;
  cursor: pointer;
  /* width: 50px; */
  /* height: 50px; */
  /* line-height: 20px; */
  /* text-align: center; */
  font-size: 16px;
}

.pagination.active {
  /* border-radius: 50%; */
  /* background-color: var(--text-color); */
  background-color: var(--blue-color);
  border-radius: 10px;
  color: #fff;
  cursor: default;
}

.pagination-separator {
  padding: 10px;
  width: 40px;
  height: 40px;
  line-height: 20px;
  text-align: center;
}

.pagination_for_table_numbers .pagination {
  font-size: 13px;
  padding: 5px;
  line-height: 15px;
  width: 25px;
  height: 25px;
}

.pagination_for_table_numbers {
  width: 100%;
  display: flex;
  justify-content: center;
}

.label-filters {
  font-size: 16px;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid var(--text-color);
  border-radius: 50%;
  margin: auto;
  z-index: 1;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
}

.loader.home {
  margin-top: 300px;
}

button.emply-label-button {
  border: none;
  background-color: transparent;
  /* margin: 0 5px; */
  cursor: pointer;
  font-size: 16px;
  line-height: 1.625;
  color: var(--text-color);
}

button.default-button {
  color: var(--blue-color);
  border: solid 1px var(--blue-color);
  padding: 5px 20px;
  transition: 0.5s;
  border-radius: 5px;
  font-size: 14px;
}
button.default-button:hover {
  background-color: var(--blue-color);
  color: #fff;
}
button.default-button.reverse {
  background-color: var(--blue-color);
  color: #fff;
}
button.default-button.reverse:hover {
  color: var(--blue-color);
  background-color: #fff;
}

button.notification-link {
  color: var(--blue-color);
  /* font-size: 12px; */
  /* padding: 0; */
  border: solid 1px var(--blue-color);
  width: 25px;
  height: 25px;
  text-align: center;
  margin-right: 5px;
  transition: 0.5s;
}
.notification-links-container > span:last-child button.notification-link {
  margin-right: 0px;
}
/* button.notification-link:hover {
  text-decoration: underline;
} */
button.notification-link:hover {
  color: var(--white-color);
  background-color: var(--blue-color);
}

.notification-link-dot {
  background-color: var(--blue-color);
  width: 30px;
  height: 30px;
  border-radius: 30px;
  margin-right: 10px;
}

.table_users_list table.table-content {
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
  border: none;
  box-shadow: var(--box-shadow);
}

.table_users_list table.table-content thead {
  background-color: var(--blue-color);
  color: #fff;
  border-radius: 15px 15px 0 0;
  text-transform: uppercase;
  font-size: 16px;
  padding: 10px 0;
}

.table_users_list table.table-content thead > tr > th {
  padding: 15px 0;
  text-align: left;
  position: relative;
  padding: 18px 30px;
  font-size: 0.9em;
}
.table_users_list table.table-content thead > tr > th::after {
  position: absolute;
  content: "";
  height: 20px;
  left: 100%;
  border-left: solid 2px #fff;
  top: 20px;
}
.table_users_list table.table-content thead > tr > th:last-child::after {
  /* border-left: none; */
}

.table_users_list table.table-content tbody {
  font-size: 14px;
  background-color: #fff;
  /* text-align: center; */
}

.table_users_list table.table-content tbody > tr > td {
  border-bottom: solid 1px #dedede;
}
.table_users_list table.table-content tbody > tr:last-child > td {
  border-bottom: none;
}

.table_users_list table.table-content tbody > tr > td {
  padding: 10px 30px;
  height: 30px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.table_users_list button.emply-label-button {
  font-size: 14px;
  line-height: 20px;
}

.table-tr-no-results {
  position: relative;
  height: 50px;
  text-align: center;
}

.table-no-results-box {
  position: absolute;
  max-width: 600px !important;
  left: 50%;
  transform: translate(-50%);
  font-size: 1.1em;
}

.detail-domain-single {
  font-size: 16px;
  line-height: 24px;
  padding: 5px 0;
}
.detail-domain-single.even {
  background-color: #eeeeee;
}

.file-list-folder-label {
  font-size: 16px;
  line-height: 24px;
  margin-left: 8px;
}

.file-list-folder-icon-label-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.file-list-folder-children-container {
  margin-left: 45px;
}
.file-list-folder-children-container .file-list-folder-row {
  position: relative;
}
.file-list-folder-children-container .file-list-folder-row:before {
  position: absolute;
  left: -30px;
  content: "↵";
  transform: rotateY(180deg);
}

.choice-view-tabel-option {
  position: relative;
  padding-left: 20px;
}

.input-checkbox {
  margin-bottom: 30px;
  max-width: 400px;
}
.input-checkbox label {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  line-height: 20px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.input-checkbox.error label {
  color: var(--red-color);
}
.input-checkbox label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: solid 1px #ccc;
  transition: 0.5s;
  border-radius: 3px;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.input-checkbox label input:checked ~ .checkmark:after {
  display: block;
}
.input-checkbox label .checkmark:after {
  left: 5px;
  top: 1px;
  width: 8px;
  height: 13px;
  border: solid var(--text-color);
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.stealer-details-container {
  margin-top: 50px;
  column-count: 2;
}

.attack-details-container {
  margin-bottom: 30px;
  padding: 0 0 8px;
  border-bottom: solid 1px var(--text-color);
}

h2.attack-details-title {
  font-size: 22px;
  font-weight: 700;
  color: var(--title-color);
}

.stealer-details-notes {
  margin-top: 50px;
  font-size: 15px;
}
.stealer-details-notes.error-get-file-from-s3 {
  color: red;
  /* TODO cambiare con un rosso degno */
}

/* .worldmap__figure-container {
  display: flex;
  justify-content: flex-start;
} */

.map-heat-color-legend {
  /* background: linear-gradient(90deg, rgba(0, 0, 255, 0) 0%, rgba(0, 0, 255, 1) 20%, rgba(0, 128, 0, 0.2) 25%, rgba(0, 128, 0, 1) 45%, rgba(255, 255, 0, 0.2) 55%, rgba(255, 255, 0, 1) 70%, rgba(255, 0, 0, 0.2) 80%, rgba(255, 0, 0, 1) 100%); */
  /* background: linear-gradient(90deg, rgba(59, 64, 129, 0) 0%, rgba(59, 64, 129, 1) 8%, rgba(73, 119, 180, 0.6) 11%, rgba(73, 119, 180, 1) 18%, rgba(111, 164, 205, 0.6) 21%, rgba(111, 164, 205, 1) 28%, rgba(176, 217, 233, 0.6) 31%, rgba(176, 217, 233, 1) 38%, rgba(232, 247, 224, 0.6) 41%, rgba(232, 247, 224, 1) 48%, rgba(254, 236, 164, 0.6) 51%, rgba(254, 236, 164, 1) 58%, rgba(250, 154, 92, 0.6) 61%, rgba(250, 154, 92, 1) 68%, rgba(238, 101, 62, 0.6) 71%, rgba(238, 101, 62, 1) 78%, rgba(210, 48, 42, 0.6) 81%, rgba(210, 48, 42, 1) 88%, rgba(172, 11, 39, 0.6) 91%, rgba(172, 11, 39, 1) 100%); */
  background: linear-gradient(
    270deg,
    #541acf 0%,
    #8550ee 20%,
    #ab8aec 40%,
    #b3d2ff 60%,
    #e5f0ff 80%,
    #f6faff 100%
  );
  height: 15px;
  /* width: calc(100% - 60px); */
  width: 100%;
  margin: 10px auto;
}

.table_numbers_container {
  width: 100%;
}

.table_numbers_row {
  width: 100%;
  display: inline-block;
  border-bottom: solid 1px var(--text-color);
  color: var(--text-color);
  transition: 0.5s;
  font-size: 16px;
}
.table_numbers_row:hover {
  background-color: #f0f0f0;
}
.table_numbers_row.intro_row {
  font-weight: bold;
}
.table_numbers_row.intro_row:hover {
  background-color: #fff;
}
.table_numbers_row_label,
.table_numbers_row_value {
  width: 50%;
  float: left;
  text-align: left;
}

.request-report-container {
  background-color: #fff;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  padding: 20px 20px;
  display: flex;
  align-items: center;
  margin: 30px 0;
}
.request-report-container > svg {
  color: var(--blue-color);
}

.request-report-text-button-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 50px;
}

.request-report-title {
  font-size: 20px;
}

.request-report-text {
  font-size: 15px;
  color: var(--blue-color);
}

.request-report-title,
.request-report-text {
  line-height: 1.3;
}

.request-report-button {
  background-color: rgba(45, 117, 218, 0.2);
  border: solid 3px var(--blue-color);
  border-radius: 5px;
  padding: 5px 50px;
  color: var(--blue-color);
  font-size: 18px;
  transition: 0.5s;
  cursor: pointer;
}
.request-report-button:hover {
  color: #fff;
  background-color: var(--blue-color);
}

.email-response {
  padding-left: 30px;
  position: relative;
  display: flex;
  align-items: center;
  /* width: max-content;
  margin: 0 auto; */
  font-weight: 700;
}
.email-response.safe {
  color: #15d2b0;
}
.email-response.progress {
  color: #2d75da;
}
.email-response.danger {
  color: #a019ce;
}

.email-response::before {
  content: " ";
  position: absolute;
  left: 0;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}
.email-response.safe::before {
  background-color: #15d2b0;
}
.email-response.progress::before {
  background-color: #2d75da;
}
.email-response.danger::before {
  background-color: #a019ce;
}

.home-box-header {
  padding: 8px 16px;
  border-radius: 0.75rem 0.75rem 0 0;
}

.alert-notification-container {
  display: flex;
  padding: 5px 20px;
}

.alert-notification-container span:nth-of-type(2) {
  text-align: right;
}

.alert-notification-container svg {
  font-size: 11px;
  margin-top: 2.5px;
  margin-right: 5px;
  vertical-align: top;
}

.alert-notification-date-container {
  font-size: 12px;
  margin-right: 20px;
}

.alert-notification-date-container span {
  display: block;
}

.alert-notification-text-container {
  flex: 3;
  padding-bottom: 12px;
  border-bottom: solid 1px #dedede;
}
.alert-notification-container:last-child .alert-notification-text-container {
  border-bottom: none;
}
.alert-notification-title {
  font-size: 13px;
  font-weight: bold;
  /* margin-bottom: 10px; */
  line-height: 20px;
}
.alert-notification-text {
  font-size: 14px;
  line-height: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.report-dot-status-container {
  font-size: 15px;
  display: flex;
  align-items: center;
}

.report-dot-status {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  background-color: var(--blue-color);
  margin-left: 10px;
}
.report-dot-status.error {
  background-color: var(--red-color);
}
.report-dot-status.pending {
  background-color: var(--purple-color);
}
.report-dot-status.fulfilled {
  background-color: var(--green-color);
}

.report-status-text {
  font-size: 16px;
}
.report-status-text.error {
  color: var(--red-color);
}
.report-status-text.pending {
  color: var(--purple-color);
}

.input-error .MuiOutlinedInput-root fieldset {
  border-color: var(--red-color) !important;
}

ul.MuiList-root.MuiList-padding a.active li > div,
ul.MuiList-root.MuiList-padding a.active li > div > div {
  background-color: #2c74e1;
  color: #ffffff;
}

.css-q1lgn6 {
  position: fixed;
  z-index: 2;
}

.MuiDrawer-root.MuiDrawer-docked .css-1ioqvny-MuiPaper-root-MuiDrawer-paper {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}
.MuiDrawer-root. .css-1ioqvny-MuiPaper-root-MuiDrawer-paper::-webkit-scrollbar {
  width: 0px; /* For Chrome, Safari, and Opera */
}

.css-1ioqvny-MuiPaper-root-MuiDrawer-paper,
.css-166o8s3 {
  max-height: 858.75px;
  position: absolute !important;
  right: 0px;
}

.css-65ycpr {
  position: fixed;
}

.boxes-border-radius {
  border-radius: 0.75rem;
}

#home-global-line-divider-one {
  height: 300px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* react date time */
.rdt {
  position: relative;
}
.rdt .rdtPicker {
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  -ms-transition: all 150ms linear;
  transition: all 150ms linear;
  margin-top: -20px;
  visibility: hidden;
  display: block;
  opacity: 0;
}
.rdt.rdtOpen .rdtPicker {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}
.rdt input.form-control {
  /* max-width: 100.48px; */
  border: solid 1px #d2d6da;
  background-color: transparent;
  border-radius: 0.375rem;
  float: none;
  box-shadow: none;
  line-height: 1.4375em;
  display: block;
  padding: 0 10px;
  color: #495057;
  height: 44px;
  font-size: 14px;
}

.rdtPicker {
  display: none;
  position: absolute;
  width: 260px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  border-radius: 0.125rem;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  min-width: 160px;
}
.rdtPicker:before {
  display: inline-block;
  position: absolute;
  width: 0;
  height: 0;
  vertical-align: middle;
  content: "";
  top: -5px;
  left: 10px;
  right: auto;
  color: #ffffff;
  border-bottom: 0.4em solid;
  border-right: 0.4em solid transparent;
  border-left: 0.4em solid transparent;
}
.rdtPicker:after {
  border-bottom: 0.4em solid #ffffff;
  border-right: 0.4em solid transparent;
  border-left: 0.4em solid transparent;
  content: "";
  display: inline-block;
  position: absolute;
  top: -5px;
  left: 10px;
}

.rdtPicker {
  display: block;
  top: 40px;
}

.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
  padding: 5px;
  border-radius: 4px;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
  border-color: #ffffff !important;
  border-collapse: collapse;
}

.rdtPicker td,
.rdtPicker th {
  text-align: center;
  padding: 1px;
}

.rdtPicker td {
  cursor: pointer;
}

.rdtDay {
  height: 30px;
  line-height: 33px;
  width: 30px;
  text-align: center;
  padding: 0px;
  border-radius: 50%;
}
.rdtDay.rdtToday.rdtActive,
.rdtDay.rdtActive,
.rdtDay.rdtActive:hover {
  background-color: var(--text-color) !important;
  color: #ffffff;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(156, 39, 176, 0.4);
}

.rdtDays tr .dow {
  border-bottom: 1px solid #e3e3e3;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: inherit;
  padding-bottom: 5px;
  padding-top: 10px;
}

.rdtDays tr .rdtOld,
.rdtDays tr .rdtNew {
  color: #bdbdbd;
}

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer;
}

.rdtPicker td.rdtToday {
  position: relative;
}

.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}

.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
  color: #999999;
}

.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
}

.rdtPicker th.rdtSwitch {
  width: 50px;
  padding: 5px;
  border-radius: 4px;
}

.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
  border-radius: 50%;
  line-height: 33px;
}

.rdtPicker .dow,
.rdtPicker th.rdtSwitch,
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev,
.rdtPicker .rdtTimeToggle {
  color: var(--text-color);
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker thead tr:first-child th {
  cursor: pointer;
}

.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}

.rdtPicker button:hover {
  background-color: #eee;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}

td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters > div {
  float: left;
  width: 40px;
  font-weight: inherit;
  margin: 3px;
  border-radius: 50%;
}

.rdtCounters .rdtCounterSeparator {
  width: 0;
  border: 1px solid transparent;
}

.rdtCounter {
  height: 100px;
}

.rdtCounter {
  width: 40px;
}
.rdtCounter .rdtCount {
  padding: 7px;
  height: 40px;
  border: 1px solid transparent;
}

.rdtCounters .rdtCounter:last-child .rdtCount {
  color: var(--text-color);
  border-radius: 50%;
  border: 1px solid var(--text-color);
}

.rdtCounterSeparator {
  padding: 7px;
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  line-height: 40px;
  cursor: pointer;
  display: block;
  border-radius: 50%;
  color: #eeeeee;
  -webkit-transition: all 60ms ease-in;
  -moz-transition: all 60ms ease-in;
  -o-transition: all 60ms ease-in;
  -ms-transition: all 60ms ease-in;
  transition: all 60ms ease-in;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

.rdtCounter .rdtBtn:hover {
  background: #eee;
  color: #797979;
}

.rdtCounter .rdtCount {
  font-size: inherit;
  line-height: 25px;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: inherit;
  margin-top: 37px;
}

.rdtMonths,
.rdtYears {
  padding-bottom: 10px;
}
.rdtMonths .rdtMonth,
.rdtMonths .rdtYear,
.rdtYears .rdtMonth,
.rdtYears .rdtYear {
  display: inline-block;
  width: 56px;
  height: 56px;
  line-height: 56px;
  margin: 3px 3px;
  cursor: pointer;
  border-radius: 50%;
  text-align: center;
}
.rdtMonths .rdtMonth.rdtActive,
.rdtMonths .rdtYear.rdtActive,
.rdtYears .rdtMonth.rdtActive,
.rdtYears .rdtYear.rdtActive {
  background-color: var(--text-color) !important;
  color: #ffffff;
}

.tabs-view-container {
  /* border-bottom: solid 1px var(--text-color); */
  width: fit-content;
  /* margin-bottom: 20px; */
}

.tabs-view-container button.emply-label-button {
  margin: 0 7px;
  padding: 5px 5px;
  transition: 0.5s;
  font-size: 0.8em;
  /* border-bottom: solid 1px var(--text-color); */
}
.tabs-view-container button.emply-label-button.tab-button-selected {
  /* border-radius: 8px 8px 0 0;
  border-top: solid 1px var(--text-color);
  border-left: solid 1px var(--text-color);
  border-right: solid 1px var(--text-color); */
  padding: 0px 10px;
  border-bottom: none;
  /* font-weight: bold; */
  color: #fff;
  background-color: var(--blue-color);
  border-radius: 5px;
}

.notification-number-in-header {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 10px;
  width: 15px;
  height: 15px;
  display: flex;
  text-align: center;
  align-items: center;
  background-color: red;
  justify-content: center;
  color: #fff;
  border-radius: 50%;
}

.detailsStealerTable {
  /* column-count: 2; */
}

.details-stealer-table-container {
  margin: 50px 0;
}

.details-stealer-table-title {
  font-size: 22px;
  font-weight: bold;
}

.detailsStealerTableRow {
  /* border: solid 1px var(--text-color); */
  padding: 5px 15px;
  /* border-bottom: 0; */
  display: inline-block;
  width: 100%;
  margin-bottom: -10px;
}
.details-stealer-table .detailsStealerTableRow.head-table-row {
  color: #fff;
  /* background-color: #505050; */
  background-color: var(--blue-color);
  border: none;
  font-size: 16px;
  padding: 10px 0;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 15px 15px 0 0;
}
.detailsStealerTableRow.head-table-row > span {
  position: relative;
  padding: 10px 0;
}
.detailsStealerTableRow.head-table-row > span::after {
  position: absolute;
  content: "";
  right: 0;
  height: 20px;
  border-left: solid 2px #fff;
  top: 15px;
}
.detailsStealerTableRow.head-table-row > span:last-child::after {
  border-left: none;
}
.detailsStealerTableRow span {
  float: left;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0 10px;
}
.detailsStealerTableRow.table-even-row {
  /* background-color: #f0f0f0; */
}
.detailsStealerTableRow span.label-cell {
  width: 40%;
  text-align: center;
}
.detailsStealerTableRow span.elem-cell {
  width: 30%;
  /* text-align: right; */
  text-align: center;
  /* border-left: solid 1px var(--text-color); */
}

.details-stealer-table {
  /* border-left: solid 1px #505050;
  border-right: solid 1px #505050;
  border-bottom: solid 1px #505050; */
  box-shadow: var(--box-shadow);
  border-radius: 15px;
}
.details-stealer-table .detailsStealerTableRow {
  font-size: 14px;
  padding: 10px 0;
  background-color: #fff;
  border-bottom: solid 2px #dedede;
}
.details-stealer-table .detailsStealerTableRow:last-child {
  border-bottom: none;
  border-radius: 0 0 15px 15px;
}

.details-stealer-cookie-value {
  width: 100%;
  display: inline-block;
}

.details-stealer-cookie-value .label {
  display: block;
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 40px);
  max-width: 380px;
}

.details-stealer-cookie-value .icon {
  float: right;
}
.details-stealer-cookie-value .icon > button {
  display: flex;
}
.details-stealer-cookie-value .icon > button > span {
  overflow: initial;
  font-size: 17px;
  padding: 0;
  color: var(--blue-color);
}

.autocomplete-container {
  width: 100%;
  position: relative;
  margin-right: 10px;
}

.autocomplete-list-container {
  position: absolute;
  right: 0;
  left: 0;
  top: 50px;
  z-index: 2;
  max-height: 500px;
  overflow-y: auto;
  display: block;
  border: solid 1px #495057;
  border-radius: 5px;
  background-color: #fff;
}

button.autocomplete-list-option {
  background-color: transparent;
  border: none;
  width: 100%;
  text-align: left;
  border-bottom: solid 1px #495057;
  padding: 10px;
  font-size: 15px;
  color: var(--text-color);
  transition: 0.5s;
}
button.autocomplete-list-option:hover,
button.autocomplete-list-option.option-selected {
  font-weight: bold;
  background-color: #f0f0f0;
}

/* Auth page */
.css-16pyyvj div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.auth-boxes {
  max-width: 32px;
  min-height: 50px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  text-align: center;
  font-size: 1.8em;
}

.css-6u9mlw:focus {
  background: #2c74e1;
}

.css-6u9mlw:hover span {
  color: white;
}

.dashboard-credentials-boxes {
  width: calc(100% / 2 - 8px);
  border-radius: 0.75rem;
}

.css-yytw9c-MuiGrid-root {
  padding-top: 0px;
  /* padding-top: 0px !important; */
}

.css-1h15ts-MuiPaper-root-MuiCard-root {
  padding: 15px 30px;
  justify-content: center;
}

/* App bar */
.css-1fw4qka-MuiToolbar-root,
.css-1fo57yj-MuiButtonBase-root-MuiIconButton-root {
  padding: 0px;
}

.email-blur {
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 0.75rem;
}

.blurred-image {
  width: 100%;
  height: 241.8px;
}

.dashboard-alerts-time {
  font-size: 15px;
  color: lightgrey;
}

.attack-details-table,
#world-map-top-countries-table {
  border-collapse: collapse;
  overflow: hidden;
}

.attack-details-table {
  width: 100%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 12px;
}

.attack-details-table thead {
  background: #2d75da;
  padding: 20px;
  color: white;
  font-size: 0.8em;
  font-weight: 600;
}

.attack-details-table thead td {
  padding: 12px 75px;
  position: relative;
}

.attack-details-table tbody tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: white;
  font-size: 0.7em;
}

.attack-details-table tbody td {
  padding: 10px 75px;
}

[class^="email-details-risk"] {
  font-weight: 700;
}

[class^="email-details-risk"]::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 15px;
}

.email-details-risk-low {
  color: #17d2b1;
}

.email-details-risk-low::before {
  background: #17d2b1;
}

.email-details-risk-medium {
  color: #4a85e0;
}

.email-details-risk-medium::before {
  background: #4a85e0;
}

.email-details-risk-high {
  color: #a01acf;
}

.email-details-risk-high::before {
  background: #a01acf;
}

.details-pages-icons {
  max-width: 25px;
}

#email-details-icon-sender {
  max-width: 32px;
}

#email-details-checks-box {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

#email-details-checks-box h3 {
  font-size: 0.9em;
  margin-left: 15px;
}

.css-wdkm8z svg {
  font-size: 18px;
}

.css-cz6ae8 svg {
  font-size: 1.25rem;
}

.report-icon {
  max-width: 30px;
  margin-right: 30px;
}

.home-question-mark {
  position: absolute;
  color: #2c74e1;
  top: 20px;
  right: 20px;
}

.boxes-border-radius.alerts {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.css-66tthm-MuiDrawer-docked {
  width: 100px;
  position: fixed;
}

#world-map-top-countries-table {
  display: block;
  margin-top: 40px;
}

#world-map-top-countries-table th {
  font-size: 0.7em;
}

#world-map-top-countries-table tbody tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.14);
}

#world-map-top-countries-table td {
  text-align: center;
  font-size: 0.6em;
  padding: 6px 40px;
}

.MuiDrawer-paper::-webkit-scrollbar {
  display: none;
}

.css-19tu0mh {
  width: unset !important;
}

.heat-map-none {
  display: none;
}

.csv-container-downloader {
  display: inline-block;
}

.csv-download-icon {
  margin-right: 3px;
}

.auth-defenda-logo {
  width: 65%;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 30px;
}

.ransomware-table-victims-container {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: middle;
}

.overview-box-grid {
  padding-top: 16px;
  padding-bottom: 8px;
}

.add-user-client svg {
  color: #344767;
}

.add-customer-line {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.server-error-container {
  width: 100%;
  padding: 25px 30px;
}

.mfa-step {
  min-width: 45px;
}

.qrcode-string {
  text-align: center;
  font-size: 14px;
  background: #f0f7ff;
  padding: 5px 0;
  margin-top: 15px;
}

button.download-button-link {
  color: #2c74e1;
  cursor: pointer;
  font-size: 25px;
  background-color: transparent;
  border: none;
  transition: 0.5s;
}
button.download-button-link:hover {
  color: #153565;
}

span.download-button-link-label {
  font-size: 14px;
}

button.download-button-link-details {
  color: #2c74e1;
  cursor: pointer;
  font-size: 16px;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  transition: 0.5s;
}
button.download-button-link-details:hover {
  color: #153565;
}

.email_error {
  color: #cf1a1a;
  font-size: 14px;
}

.ransomware_image_container {
  position: relative;
  margin-top: 20px;
  width: max-content;
}

button.delete_image_ransomware_button {
  position: absolute;
  border-radius: 50px;
  right: 0;
  top: 0;
  border: solid 1px #000;
  background-color: #fff;
  color: #000;
  width: 30px;
  height: 30px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}

img.ransomware_image {
  max-width: 200px;
  height: auto;
}

img.ransomware_big_image {
  width: 100%;
  max-width: 1000px;
  height: auto;
}

.user_info_section_container {
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: solid 1px #344767;
  margin-bottom: 10px;
}
.user_info_section_container:last-child {
  border-bottom: none;
}

.services-selection-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.service-selection {
}

button.service-to-select {
  border-radius: 10px;
  color: var(--title-color);
  border: solid 1px #c0c0c0;
  font-weight: normal;
  transition: 0.5s;
  margin: 0 5px;
  padding: 5px 15px;
}
button.service-to-select.is-selected {
  font-weight: bold;
  border-color: var(--blue-color);
  color: var(--blue-color);
}

.code-duration-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* AUTH */
.email_validation_error,
.email_validation_success,
.email_validation_checking,
.email_validation_no_code {
  text-align: center;
}
.email_validation_error h3 {
  color: var(--red-color);
}
.email_validation_success h3 {
  color: var(--green-color);
}

/* FINE AUTH */

.status-circle {
  width: 10px;
  height: 10px;
  border-radius: 25px;
}

.reports-page-description-box {
  padding: 12px;
  border-radius: 15px;
  border: 1px solid var(--blue-color);
  color: var(--blue-color);
  margin-bottom: 12px;
  font-size: small;
}

.user-notifications-form {
  display: flex;
  flex-direction: column;
}

.user-notifications-form input {
  margin-right: 6px;
}

.user-notifications-form h4:not(:first-child) {
  margin-top: 10px;
}

.user-notifications-form button {
  margin-top: 20px;
}

.success-box,
.error-box {
  margin-top: 20px;
  text-align: center;
  padding: 6px;
  font-size: 14px;
  font-weight: 600;
}

.success-box {
  background: #bfffba;
  color: #399918;
  border: 1px solid #399918;
}

.error-box {
  background: "#ffcfc7";
  color: #ff0000;
  border: 1px solid #ff0000;
}

.user-list-session-cell-box {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 11px;
}

.user-list-session-cell-box span {
  font-weight: 600;
}

ul.MuiList-root.MuiList-padding a.active li > div > div.dashboard_activities_to_view,
div.dashboard_activities_to_view {
  background-color: var(--red-color);
  font-size: 12px;
  padding: 5px;
  border-radius: 5px;
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
}

a.back-to-results-link {
  color: var(--blue-color);
  background-color: transparent;
  border: solid 1px var(--blue-color);
  transition: 0.5s;
  font-size: 14px;
  border-radius: 5px;
  padding: 5px;
}
a.back-to-results-link:hover {
  color: #fff;
  background-color: var(--blue-color);
}

.data-breach-details-intro-line {
  color: var(--purple-color);
  margin-bottom: 10px;
}

.data-breach-attacks-intro {
  color: var(--text-color);
  font-size: 18px;
  margin-top: 30px;
  margin-bottom: 20px;
}

/* Hosting fonts */

@media (max-width: 991px) {
  #global-box-line {
    display: none;
  }

  .statistics-counter {
    font-size: 20px;
  }

  .statistics-counter-text-container {
    font-size: 12px;
    line-height: 15px;
  }

  .statistics-counter-container {
    border-right: none !important;
  }

  .overview-box-grid {
    padding-top: 25px;
    padding-bottom: 30px;
  }

  #home-global-line-divider-two {
    width: 80%;
    margin: auto;
    border: 1px solid rgb(44, 116, 225, 0.1);
  }
}

@media (min-width: 1440px) {
  #root {
    margin: auto;
  }

  .css-vuuwxc {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .css-1wr0bwh-MuiTypography-root {
    line-height: 1.1;
    font-size: 0.8rem;
  }
}

@media (max-width: 767px) {
  .stealer-details-container {
    column-count: 1;
  }
}
